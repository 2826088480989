import AvatarWithStatus from "@/components/AvatarWithStatus";
import CrownIcon from "@/components/Icons/Crown";
import Locator from "@/locator";
import type { AccountInfo } from "@/models/accountInfo";
import * as Icons from "@mui/icons-material";
import { ListItemIcon, Tooltip } from "@mui/material";
import Box from "@mui/material/Box";

export default function MemberDrawerListItem({
  account,
}: { account: AccountInfo }) {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        position: "relative",
        p: 1,
        alignItems: "center",
      }}
    >
      <Box sx={{ width: "40px", mr: 1 }}>
        <ListItemIcon sx={{ position: "relative", zIndex: 1 }}>
          <AvatarWithStatus accountId={account?.id} key={account?.id} />
        </ListItemIcon>
      </Box>
      <Box>
        <Box>{account.name || account.id}</Box>
        <Box sx={{ display: "flex", alignItems: "center", color: "#ccc" }}>
          {account.isAdmin && (
            <Tooltip title="Feed Admin">
              <Box sx={{ mr: 1, display: "flex", alignItems: "center" }}>
                <CrownIcon
                  name="Feed Admin"
                  aria-label={Locator.feed.members.list.isAdmin}
                  fill="#ccc"
                  height={16}
                  width={16}
                />
              </Box>
            </Tooltip>
          )}
          {account.isMuted && (
            <Tooltip title="Muted">
              <Box sx={{ mr: 1, display: "flex", alignItems: "center" }}>
                <Icons.MicOff
                  fontSize="small"
                  role="img"
                  aria-label={Locator.feed.members.list.isMuted}
                />
              </Box>
            </Tooltip>
          )}
        </Box>
      </Box>
    </Box>
  );
}
