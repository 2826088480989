/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  BootstrapDeviceDrivingStatuses,
  BootstrapLatestUnreadEventPerFeed,
  BootstrapWorkspaceAccounts,
  BootstrapWorkspaceCommandAliases,
  BootstrapWorkspaceFeedGroupMemberships,
  BootstrapWorkspaceFeedGroups,
  BootstrapWorkspaceFeedItems,
  BootstrapWorkspaceFeedPermissions,
  BootstrapWorkspaceFeedPreferences,
  BootstrapWorkspaceFeeds,
  BootstrapWorkspaceMembershipDrivingStatuses,
  BootstrapWorkspaceMemberships,
  BootstrapWorkspacesResponse,
  BootstrapWorkspaceTemplates,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class Bootstrap<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @name WorkspacesDeviceDrivingStatusesDetail
   * @summary BootstrapDeviceDrivingStatuses
   * @request GET:/bootstrap/workspaces/{id}/device-driving-statuses
   */
  workspacesDeviceDrivingStatusesDetail = (
    id: string,
    query?: {
      page?: number;
      pageSize?: number;
      timestamp?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<BootstrapDeviceDrivingStatuses, any>({
      path: `/bootstrap/workspaces/${id}/device-driving-statuses`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name WorkspacesAccountsDetail
   * @summary bootstrapWorkspaceAccounts
   * @request GET:/bootstrap/workspaces/{id}/accounts
   */
  workspacesAccountsDetail = (
    id: string,
    query?: {
      page?: number;
      pageSize?: number;
      timestamp?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<BootstrapWorkspaceAccounts, any>({
      path: `/bootstrap/workspaces/${id}/accounts`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name WorkspacesCommandAliasesDetail
   * @summary bootstrapWorkspaceCommandAliases
   * @request GET:/bootstrap/workspaces/{id}/command-aliases
   */
  workspacesCommandAliasesDetail = (
    id: string,
    query?: {
      page?: number;
      pageSize?: number;
      timestamp?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<BootstrapWorkspaceCommandAliases, any>({
      path: `/bootstrap/workspaces/${id}/command-aliases`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name WorkspacesFeedGroupMembershipsDetail
   * @summary bootstrapWorkspaceFeedGroupMemberships
   * @request GET:/bootstrap/workspaces/{id}/feed-group-memberships
   */
  workspacesFeedGroupMembershipsDetail = (
    id: string,
    query?: {
      page?: number;
      pageSize?: number;
      timestamp?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<BootstrapWorkspaceFeedGroupMemberships, any>({
      path: `/bootstrap/workspaces/${id}/feed-group-memberships`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name WorkspacesFeedGroupsDetail
   * @summary bootstrapWorkspaceFeedGroups
   * @request GET:/bootstrap/workspaces/{id}/feed-groups
   */
  workspacesFeedGroupsDetail = (
    id: string,
    query?: {
      page?: number;
      pageSize?: number;
      timestamp?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<BootstrapWorkspaceFeedGroups, any>({
      path: `/bootstrap/workspaces/${id}/feed-groups`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name WorkspacesFeedItemsDetail
   * @summary bootstrapWorkspaceFeedItemsPaginated
   * @request GET:/bootstrap/workspaces/{id}/feed-items
   */
  workspacesFeedItemsDetail = (
    id: string,
    query?: {
      page?: number;
      pageSize?: number;
      timestamp?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<BootstrapWorkspaceFeedItems, any>({
      path: `/bootstrap/workspaces/${id}/feed-items`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name WorkspacesFeedPermissionsDetail
   * @summary bootstrapWorkspaceFeedPermissions
   * @request GET:/bootstrap/workspaces/{id}/feed-permissions
   */
  workspacesFeedPermissionsDetail = (
    id: string,
    query?: {
      page?: number;
      pageSize?: number;
      timestamp?: string;
      mine?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<BootstrapWorkspaceFeedPermissions, any>({
      path: `/bootstrap/workspaces/${id}/feed-permissions`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name WorkspacesFeedPreferencesDetail
   * @summary bootstrapWorkspaceFeedPreferences
   * @request GET:/bootstrap/workspaces/{id}/feed-preferences
   */
  workspacesFeedPreferencesDetail = (
    id: string,
    query?: {
      page?: number;
      pageSize?: number;
      timestamp?: string;
      mine?: boolean;
      feedId?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<BootstrapWorkspaceFeedPreferences, any>({
      path: `/bootstrap/workspaces/${id}/feed-preferences`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name WorkspacesFeedsDetail
   * @summary bootstrapWorkspaceFeeds
   * @request GET:/bootstrap/workspaces/{id}/feeds
   */
  workspacesFeedsDetail = (
    id: string,
    query?: {
      page?: number;
      pageSize?: number;
      timestamp?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<BootstrapWorkspaceFeeds, any>({
      path: `/bootstrap/workspaces/${id}/feeds`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name WorkspacesWorkspaceMembershipDrivingStatusesDetail
   * @summary bootstrapWorkspaceMembershipDrivingStatuses
   * @request GET:/bootstrap/workspaces/{id}/workspace-membership-driving-statuses
   */
  workspacesWorkspaceMembershipDrivingStatusesDetail = (
    id: string,
    query?: {
      page?: number;
      pageSize?: number;
      timestamp?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<BootstrapWorkspaceMembershipDrivingStatuses, any>({
      path: `/bootstrap/workspaces/${id}/workspace-membership-driving-statuses`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name WorkspacesWorkspaceMembershipsDetail
   * @summary bootstrapWorkspaceMemberships
   * @request GET:/bootstrap/workspaces/{id}/workspace-memberships
   */
  workspacesWorkspaceMembershipsDetail = (
    id: string,
    query?: {
      page?: number;
      pageSize?: number;
      timestamp?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<BootstrapWorkspaceMemberships, any>({
      path: `/bootstrap/workspaces/${id}/workspace-memberships`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name WorkspacesTemplatesDetail
   * @summary bootstrapWorkspaceTemplates
   * @request GET:/bootstrap/workspaces/{id}/templates
   */
  workspacesTemplatesDetail = (
    id: string,
    query?: {
      page?: number;
      pageSize?: number;
      timestamp?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<BootstrapWorkspaceTemplates, any>({
      path: `/bootstrap/workspaces/${id}/templates`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name WorkspacesList
   * @summary bootstrapWorkspaces
   * @request GET:/bootstrap/workspaces
   */
  workspacesList = (params: RequestParams = {}) =>
    this.request<BootstrapWorkspacesResponse, any>({
      path: `/bootstrap/workspaces`,
      method: "GET",
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name WorkspacesLatestUnreadEventPerFeedDetail
   * @summary latestUnreadEventPerFeed
   * @request GET:/bootstrap/workspaces/{id}/latest-unread-event-per-feed
   */
  workspacesLatestUnreadEventPerFeedDetail = (
    id: string,
    query?: {
      page?: number;
      pageSize?: number;
    },
    params: RequestParams = {},
  ) =>
    this.request<BootstrapLatestUnreadEventPerFeed, any>({
      path: `/bootstrap/workspaces/${id}/latest-unread-event-per-feed`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @name WorkspacesLatestUnreadEventPerFeedForOrganizersDetail
   * @summary latestUnreadEventPerFeedForOrganizers
   * @request GET:/bootstrap/workspaces/{id}/latest-unread-event-per-feed-for-organizers
   */
  workspacesLatestUnreadEventPerFeedForOrganizersDetail = (
    id: string,
    query?: {
      page?: number;
      pageSize?: number;
      timestamp?: string;
    },
    params: RequestParams = {},
  ) =>
    this.request<BootstrapLatestUnreadEventPerFeed, any>({
      path: `/bootstrap/workspaces/${id}/latest-unread-event-per-feed-for-organizers`,
      method: "GET",
      query: query,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
