import Feed from "@/components/Feed";
import Loading from "@/components/Loading/Index";
import NoRightsToViewContentModal from "@/components/NoRightsToViewContentModal";
import { db } from "@/db/db";
import { feed, permission, workspaceMembership } from "@/db/schema";
import useLocationChange from "@/hooks/useLocationChange";
import { DataContext } from "@/models/DataProvider";
import FeedContextProvider from "@/models/FeedContextProvider";
import { LiveQueryContext } from "@/models/LiveQueriesProvider";
import { CurrentFeedContext } from "@/models/StateProviders/currentFeedProvider";
import { MyAccountContext } from "@/models/StateProviders/myAccountProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { Stack } from "@mui/material";
import { and, eq } from "drizzle-orm";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "regenerator-runtime/runtime";

export default function FeedId() {
  const { currentFeedId, currentFeed } = useContext(CurrentFeedContext);
  const { availableWorkspaceRoles } = useContext(DataContext);
  const { myAccountId } = useContext(MyAccountContext);
  const { fetchNextActiveChannel } = useContext(LiveQueryContext);
  const { myCurrentWorkspaceMembership } = useContext(WorkspaceContext);
  const navigate = useNavigate();
  const [invalidFeed, setInvalidFeed] = useState<boolean>(false);
  const [renderToggle, setRenderToggle] = useState<string>("");

  useEffect(() => {
    if (history.scrollRestoration) {
      history.scrollRestoration = "manual";
    }
  }, []);

  // WEB-767: Set if a user is on a page, since removing their permissions does a check and re-joins them to a channel. If the user is on the page and their permissions are removed, they should be redirected to the next active channel.
  const [isUserOnPage, setIsUserOnPage] = useState<boolean>(false);

  useLocationChange(() => {
    setIsUserOnPage(() => false);
    setTimeout(() => {
      setIsUserOnPage(() => true);
    }, 3000);
  });

  const checkFeedPermissions = async ({
    workspaceMembershipId,
    feedId,
    isUserOnPage,
  }: {
    workspaceMembershipId: string;
    feedId: string;
    isUserOnPage: boolean;
  }) => {
    const permissionRecord = await db.query.permission.findFirst({
      where: and(
        eq(permission.feedId, feedId),
        eq(permission.workspaceMembershipId, workspaceMembershipId),
        eq(permission.enabled, true),
        eq(permission.name, "read"),
      ),
    });
    if (!permissionRecord) {
      if (isUserOnPage) {
        // if the user is on a feed, but had their permissions revoked, redirect them to the workspace
        return Promise.reject("redirect");
      }
      return Promise.reject("No Feed Permissions");
    }
    return true;
  };

  useEffect(() => {
    const f = async () => {
      if (myCurrentWorkspaceMembership) {
        checkFeedPermissions({
          feedId: currentFeedId,
          workspaceMembershipId: myCurrentWorkspaceMembership.id,
          isUserOnPage,
        })
          .then(() => {
            setInvalidFeed(() => false);
          })
          .catch(async (value) => {
            if (value && value === "redirect") {
              const redirectUrl = await fetchNextActiveChannel();
              return navigate(redirectUrl);
            } else {
              setInvalidFeed(() => true);
              navigate(
                `/workspaces/${myCurrentWorkspaceMembership.workspaceId}`,
              );
            }
          });
      } else {
        setInvalidFeed(() => true);
        navigate(`/workspaces`);
      }
    };
    f();
  }, [myCurrentWorkspaceMembership, currentFeedId, myAccountId, isUserOnPage]);

  if (invalidFeed) {
    return <NoRightsToViewContentModal open={true} />;
  }

  // const keyForContext = `${currentFeedId}-${renderToggle}`;
  // const keyForFeed = `feed-${currentFeedId}-${renderToggle}`;
  const keyForContext = "feed";

  useEffect(() => {
    console.log("TOGGLE", renderToggle);
  }, [renderToggle]);

  return (
    <FeedContextProvider key={keyForContext}>
      {!currentFeed ? (
        <Stack
          sx={{
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Loading />
        </Stack>
      ) : (
        <Feed setRenderToggle={setRenderToggle} />
      )}
    </FeedContextProvider>
  );
}
