import NoRightsToViewContentModal from "@/components/NoRightsToViewContentModal";
import { DataContext } from "@/models/DataProvider";
import { LiveQueryContext } from "@/models/LiveQueriesProvider";
import { WorkspaceContext } from "@/models/StateProviders/workspaceProvider";
import { UxContext } from "@/models/UxStateProvider";
import LoadingButton from "@mui/lab/LoadingButton";
import { Typography, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function NoChannelsAvailable() {
  const theme = useTheme();
  const navigate = useNavigate();
  const params = useParams();
  const workspaceId = params?.workspaceId as string;
  const { myActiveFeeds } = useContext(LiveQueryContext);
  const { availableWorkspaceRoles } = useContext(DataContext);
  const { myCurrentWorkspaceMembership } = useContext(WorkspaceContext);

  const canCreateChannel =
    myCurrentWorkspaceMembership &&
    myCurrentWorkspaceMembership?.role !==
      availableWorkspaceRoles?.get("limitedMember")?.role;
  const validWorkspace = !!myCurrentWorkspaceMembership;

  useEffect(() => {
    if (myActiveFeeds?.length > 0) {
      navigate(`/workspaces/${workspaceId}/feeds/${myActiveFeeds[0].id}`);
    }
  }, [myActiveFeeds]);

  return (
    <>
      <NoRightsToViewContentModal open={!validWorkspace} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
        data-feed-layout-wrap
      >
        <Stack
          sx={{
            mx: "auto",
            display: "flex",
            justifyContent: "center",
            my: 6,
            maxWidth: "600px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              textAlign: "center",
              gap: "28px",
              width: "100%",
              maxWidth: { xs: "calc(100% - 32px)", sm: "415px" },
              padding: "40px 60px",
              borderRadius: "8px",
              background: theme.palette.secondary.dark,
            }}
          >
            <Box>
              <Typography
                variant="h6"
                component="h4"
                color={theme.palette.primary.main}
                fontWeight={700}
              >
                No channels available
              </Typography>
            </Box>
          </Box>
        </Stack>
      </Box>
    </>
  );
}
