import { upsertAccountEvent } from "@/data/oldWorld";
import {
  deleteWsDraft,
  upsertDirectWsInvitation,
  upsertWorkspaceMembership,
  upsertWsBroadcastAction,
  upsertWsBroadcastRecipient,
  upsertWsDraft,
  upsertWsFeed,
  upsertWsFeedPreference,
  upsertWsFeedPreferences,
  upsertWsItem,
  upsertWsLink,
  upsertWsScheduleTrigger,
  upsertWsTranscription,
} from "@/data/pg/updates";
import { db } from "@/db/db";
import { accountEvent as accountEventTable, feed, item } from "@/db/schema";
import { UxContext } from "@/models/UxStateProvider";
import cuid from "cuid";
import { and, eq } from "drizzle-orm";
import React, { createContext, useContext } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { useNavigate } from "react-router-dom";
import {
  AccountEventRequest,
  AccountEventType,
  CreateWorkspaceDirectInvitationResponse,
  CreateWorkspaceScheduledBroadcastResponse,
  PublishBroadcastResponse,
  WorkspaceInvitation,
  WorkspaceRole,
  WsFeed,
  WsFeedPreference,
  WsWorkflowItem,
} from "web-client/api/data-contracts";
import Client from "web-client/client";
import { DataContext } from "./DataProvider";
import { WorkspaceContext } from "./StateProviders/workspaceProvider";
import { TelemetryContext } from "./TelemetryProvider";
import { initializeUnreadsForAllFeeds } from "./actions/initialFeedLoad";

export type InterimItem = {
  id: string;
  feedId: string;
  contentId: string;
  groupId?: string;
  isSilent?: boolean;
};

type ActionState = {
  accountEvent?: (event: AccountEventType, data: any) => void;
  createScheduledWorkflowItem?: ({
    workspaceId,
    workflowItemId,
    feedIds,
    scheduledDate,
    scheduledCron,
    timezone,
  }: {
    workspaceId: string;
    workflowItemId: string;
    feedIds: Array<string>;
    scheduledDate?: string;
    scheduledCron?: string;
    timezone?: string;
  }) => Promise<CreateWorkspaceScheduledBroadcastResponse>;
  updateScheduledWorkflowItem?: ({
    workspaceId,
    scheduleId,
    feedIds,
    scheduledDate,
    scheduledCron,
    timezone,
  }: {
    workspaceId: string;
    scheduleId: string;
    feedIds: Array<string>;
    scheduledDate?: string;
    scheduledCron?: string;
    timezone?: string;
  }) => Promise<void>;
  deleteItem?: (itemId: string) => Promise<void>;
  createWorkflowItem?: (
    workspaceId: string,
    contentId?: string,
    displayName?: string,
    text?: string,
  ) => Promise<WsWorkflowItem>;
  deleteWorkflowItem?: (
    workspaceId: string,
    workflowItemId: string,
  ) => Promise<void>;
  publishBroadcast?: ({
    workspaceId,
    contentId,
    workspaceMembershipIds,
    feedIds,
    inputText,
  }: {
    workspaceId: string;
    contentId: string;
    workspaceMembershipIds?: string[];
    feedIds?: string[];
    inputText?: string;
  }) => Promise<PublishBroadcastResponse>;
  publishToWorkspaceFeed?: ({
    workspaceId,
    feedId,
    itemId,
    contentId,
    groupId,
    url,
    text,
    preferredLanguage,
    isSilent,
  }: {
    workspaceId: string;
    feedId: string;
    itemId?: string;
    contentId: string;
    groupId?: string;
    url?: string;
    text?: string;
    preferredLanguage?: string;
    isSilent?: boolean;
  }) => Promise<InterimItem>;
  sendWorkspaceInvites?: (
    workspaceId: string,
    emails?: string[],
    phoneNumbers?: string[],
    sendEmail?: boolean,
    invites?: WorkspaceInvitation[],
  ) => Promise<CreateWorkspaceDirectInvitationResponse>;
  updateMemberRole?: (
    workspaceId: string,
    workspaceMembershipIds: string[],
    role: WorkspaceRole,
  ) => Promise<void>;
  removeMember?: (
    workspaceId: string,
    workspaceMembershipIds: string[],
  ) => Promise<void>;
  sendFeedback?: (message: string) => Promise<void>;
  subscribeToFeed?: (params: {
    workspaceId: string;
    feedId: string;
    workspaceMembershipId: string;
  }) => Promise<WsFeedPreference>;
  unsubscribeFromFeed?: (params: {
    workspaceId: string;
    feedId: string;
    workspaceMembershipId: string;
  }) => Promise<WsFeedPreference>;
  subscribeToFeedGroup?: (params: {
    workspaceId: string;
    feedGroupId: string;
    workspaceMembershipId: string;
  }) => Promise<WsFeedPreference[]>;
  unsubscribeFromFeedGroup?: (params: {
    workspaceId: string;
    feedGroupId: string;
    workspaceMembershipId: string;
  }) => Promise<WsFeedPreference[]>;
};

export const ActionContext = createContext<ActionState>({});

type Props = {
  children: React.ReactNode | React.ReactNode[];
  client: Client;
};

const ActionsProvider = ({ children, client }: Props) => {
  const { userReadOnlyMode } = useContext(UxContext);
  const { trackAction, finishAction } = useContext(TelemetryContext);
  const { myCurrentWorkspaceMembership } = useContext(WorkspaceContext);

  const { appContext, deviceContext } = useContext(DataContext);

  const accountEvent = React.useCallback(
    async (name: AccountEventType, data: any) => {
      if (userReadOnlyMode === true) {
        console.log("Read only mode enabled, not sending event");
        return;
      }

      if (!myCurrentWorkspaceMembership) return;
      const myAcocuntId = myCurrentWorkspaceMembership.accountId;
      if (data.feedId && data.feedItemId) {
        const existingEvent = await db.query.accountEvent
          .findFirst({
            where: and(
              eq(accountEventTable.name, name),
              eq(accountEventTable.feedId, data.feedId),
              eq(accountEventTable.itemId, data.feedItemId),
              eq(accountEventTable.accountId, myAcocuntId),
            ),
          })
          .execute();

        if (existingEvent) {
          console.log("Already sent event", existingEvent);
          return await db
            .update(item)
            // @ts-ignore
            .set({ hasRegisteredSeenEvent: true })
            .where(eq(item.id, data.feedItemId))
            .execute();
        }
      }
      const event: AccountEventRequest = {
        id: cuid(),
        name,
        ...data,
        timestamp: new Date(Date.now()).toISOString(),
        accountId: myAcocuntId,
      };
      client.createAccountEvent(event);
      await upsertAccountEvent(event);
      await initializeUnreadsForAllFeeds({
        feedIds: [event.feedId],
        membership: myCurrentWorkspaceMembership,
        alsoSetAsRead: true,
      });
    },
    [client, myCurrentWorkspaceMembership, userReadOnlyMode],
  );

  const deleteItem = React.useCallback(
    async (itemId: string) => {
      const feedItem = await db.query.item
        .findFirst({ where: eq(item.id, itemId) })
        .execute();
      const feedRecord = await db.query.feed
        .findFirst({
          where: eq(feed.id, feedItem?.feedId),
        })
        .execute();
      if (!feedRecord || !feedRecord.workspaceId || !itemId) return;
      const res = await client.deleteWorkspaceFeedItem(
        feedRecord.workspaceId,
        feedRecord.id,
        itemId,
      );

      if (res?.item) {
        upsertWsItem(res.item);
      }
    },
    [client, db],
  );

  const sendWorkspaceInvites = React.useCallback(
    async (
      workspaceId: string,
      emails: string[],
      phoneNumbers: string[],
      sendEmail: boolean,
      invites: WorkspaceInvitation[],
    ) => {
      const res = await client.createWorkspaceInvitations(
        workspaceId,
        emails,
        phoneNumbers,
        sendEmail,
        invites,
      );
      const invitations = res?.invitations || [];
      const workspaceMemberships = res?.workspaceMemberships || [];
      for (const i of invitations) {
        upsertDirectWsInvitation(i);
      }
      for (const m of workspaceMemberships) {
        upsertWorkspaceMembership(m);
      }

      return {
        invitations,
        workspaceMemberships,
      };
    },
    [client, db],
  );

  const updateMemberRole = React.useCallback(
    async (
      workspaceId: string,
      workspaceMembershipIds: string[],
      role: WorkspaceRole,
    ) => {
      const resp = await client.updateWorkspaceMember(
        workspaceId,
        workspaceMembershipIds,
        role,
      );

      for (const workspaceMembership of resp?.workspaceMemberships || []) {
        console.log("Upserting workspace membership", workspaceMembership);
        upsertWorkspaceMembership(workspaceMembership);
      }
    },
    [client, db],
  );

  const removeMember = React.useCallback(
    async (workspaceId: string, workspaceMembershipIds: string[]) => {
      console.log(
        "Removing workspaceMembershipRecord",
        workspaceMembershipIds,
        workspaceId,
      );
      const resp = await client.removeWorkspaceMember(
        workspaceId,
        workspaceMembershipIds,
      );
      console.log("Removing workspaceMembershipRecord", resp);

      for (const workspaceMembership of resp?.workspaceMemberships || []) {
        upsertWorkspaceMembership(workspaceMembership);
      }
    },
    [client, db],
  );

  // subscribeToFeed,
  // unsubscribeFromFeed,
  // unsubscribeFromFeedGroup,
  // subscribeToFeedGroup,

  const subscribeToFeed = React.useCallback(
    async ({
      workspaceId,
      feedId,
      workspaceMembershipId,
    }: {
      workspaceId: string;
      feedId: string;
      workspaceMembershipId: string;
    }) => {
      const { feedPreference } = await client.subscribeToFeed({
        workspaceId,
        feedId,
        workspaceMembershipId,
      });
      await upsertWsFeedPreference(feedPreference);
      return feedPreference;
    },
    [client],
  );

  const unsubscribeFromFeed = React.useCallback(
    async ({
      workspaceId,
      feedId,
      workspaceMembershipId,
    }: {
      workspaceId: string;
      feedId: string;
      workspaceMembershipId: string;
    }) => {
      const { feedPreference } = await client.unsubscribeFromFeed({
        workspaceId,
        feedId,
        workspaceMembershipId,
      });
      await upsertWsFeedPreference(feedPreference);
      return feedPreference;
    },
    [client],
  );

  const subscribeToFeedGroup = React.useCallback(
    async ({
      workspaceId,
      feedGroupId,
      workspaceMembershipId,
    }: {
      workspaceId: string;
      feedGroupId: string;
      workspaceMembershipId: string;
    }) => {
      const { feedPreferences } = await client.subscribeToFeedGroup({
        workspaceId,
        feedGroupId,
        workspaceMembershipId,
      });
      await upsertWsFeedPreferences(feedPreferences);
      return feedPreferences;
    },
    [client],
  );

  const unsubscribeFromFeedGroup = React.useCallback(
    async ({
      workspaceId,
      feedGroupId,
      workspaceMembershipId,
    }: {
      workspaceId: string;
      feedGroupId: string;
      workspaceMembershipId: string;
    }) => {
      const { feedPreferences } = await client.unsubscribeFromFeedGroup({
        workspaceId,
        feedGroupId,
        workspaceMembershipId,
      });
      await upsertWsFeedPreferences(feedPreferences);
      return feedPreferences;
    },
    [client],
  );

  const createWorkflowItem = React.useCallback(
    async (
      workspaceId: string,
      contentId?: string,
      displayName?: string,
      text?: string,
    ) => {
      let workflowItem: WsWorkflowItem;
      if (text) {
        workflowItem = await client.createWorkspaceWorkflowItem({
          workspaceId: workspaceId,
          displayName: displayName,
          inputText: text,
        });
        if (!workflowItem) throw new Error("WorkflowItem not created");
      } else {
        workflowItem = await client.createWorkspaceWorkflowItem({
          workspaceId: workspaceId,
          contentId: contentId,
          displayName: displayName,
        });
        if (!workflowItem) throw new Error("WorkflowItem not created");
      }

      if (workflowItem) {
        upsertWsDraft(workflowItem);
      }

      return workflowItem;
    },
    [client, db],
  );

  const deleteWorkflowItem = React.useCallback(
    async (workspaceId: string, workflowItemId: string) => {
      await client.deleteWorkspaceWorkflowItem(workspaceId, workflowItemId);

      await deleteWsDraft(workflowItemId);
    },
    [client, db],
  );

  const createScheduledWorkflowItem = React.useCallback(
    async ({
      workspaceId,
      workflowItemId,
      feedIds,
      scheduledDate,
      scheduledCron,
      timezone,
    }: {
      workspaceId: string;
      workflowItemId: string;
      feedIds: Array<string>;
      scheduledDate?: string;
      scheduledCron?: string;
      timezone?: string;
    }) => {
      let props = {
        workspaceId: workspaceId,
        feedIds,
        workflowItemId: workflowItemId,
        date: "",
        cron: "",
        timezone: "",
      };

      if (scheduledDate) {
        props = { ...props, date: scheduledDate };
      }

      if (scheduledCron) {
        props = { ...props, cron: scheduledCron, timezone: timezone };
      }

      const scheduledBroadcast = await client.createScheduledBroadcast(props);

      if (!scheduledBroadcast)
        throw new Error("scheduledBroadcast not created");

      console.log("created broadcast", scheduledBroadcast);
      if (scheduledBroadcast) {
        if (scheduledBroadcast?.scheduleTrigger) {
          upsertWsScheduleTrigger(scheduledBroadcast?.scheduleTrigger);
        }

        if (scheduledBroadcast?.broadcastAction) {
          upsertWsBroadcastAction(scheduledBroadcast?.broadcastAction);
        }

        if (scheduledBroadcast?.broadcastRecipients) {
          for (const broadcastRecipient of scheduledBroadcast?.broadcastRecipients ||
            []) {
            upsertWsBroadcastRecipient(broadcastRecipient);
          }
        }
      }

      return scheduledBroadcast;
    },
    [client, db],
  );

  const updateScheduledWorkflowItem = React.useCallback(
    async ({
      workspaceId,
      scheduleId,
      feedIds,
      scheduledDate,
      scheduledCron,
      timezone,
    }: {
      workspaceId: string;
      scheduleId: string;
      feedIds: Array<string>;
      scheduledDate?: string;
      scheduledCron?: string;
      timezone?: string;
    }) => {
      type PropsType = {
        workspaceId: string;
        feedIds: string[];
        scheduleId: string;
        date?: string;
        cron?: string;
        timezone?: string;
      };

      let props: PropsType = {
        workspaceId: workspaceId,
        feedIds,
        scheduleId: scheduleId,
      };

      if (scheduledDate) {
        props = { ...props, date: scheduledDate };
      }

      if (scheduledCron) {
        props = { ...props, cron: scheduledCron, timezone: timezone };
      }

      const scheduledBroadcast = await client.updateScheduledBroadcast(props);

      if (!scheduledBroadcast)
        throw new Error("scheduledBroadcast not updated");

      console.log("updated broadcast", scheduledBroadcast);
      if (scheduledBroadcast) {
        if (scheduledBroadcast?.scheduleTrigger) {
          upsertWsScheduleTrigger(scheduledBroadcast?.scheduleTrigger);
        }

        if (scheduledBroadcast?.broadcastAction) {
          upsertWsBroadcastAction(scheduledBroadcast?.broadcastAction);
        }

        if (scheduledBroadcast?.broadcastRecipients) {
          for (const broadcastRecipient of scheduledBroadcast?.broadcastRecipients ||
            []) {
            upsertWsBroadcastRecipient(broadcastRecipient);
          }
        }
      }
    },
    [client, db],
  );

  const publishToWorkspaceFeed = React.useCallback(
    async ({
      workspaceId,
      feedId,
      itemId,
      contentId,
      groupId,
      text,
      preferredLanguage,
      isSilent,
    }: {
      workspaceId: string;
      feedId: string;
      itemId?: string;
      contentId: string;
      groupId?: string;
      text?: string;
      preferredLanguage?: string;
      isSilent?: boolean;
    }) => {
      const tempItem: InterimItem = {
        id: itemId || cuid(),
        feedId,
        contentId,
        groupId,
        isSilent,
      };
      client
        .publishTTS({
          workspaceId,
          feedId,
          itemId: tempItem.id,
          contentId,
          groupId,
          text,
          preferredLanguage,
          isSilent,
        })
        .then((res) => {
          const { item, links, transcriptions } = res;
          upsertWsItem(item);
          for (const link of links || []) {
            upsertWsLink(link);
          }
          for (const transcription of transcriptions || []) {
            upsertWsTranscription(transcription);
          }
        });
      return tempItem;
    },
    [client],
  );

  const publishBroadcast = React.useCallback(
    async ({
      workspaceId,
      contentId,
      workspaceMembershipIds,
      feedIds,
      inputText,
    }: {
      workspaceId: string;
      contentId: string;
      workspaceMembershipIds?: string[];
      feedIds?: string[];
      inputText?: string;
    }) => {
      trackAction(
        { action: "publish_broadcast", target: contentId },
        {
          workspaceId,
          workspaceMembershipIds,
          feedIds,
          inputText,
        },
      );
      const broadcast = await client.publishBroadcast({
        workspaceId,
        contentId,
        workspaceMembershipIds,
        feedIds,
        inputText,
      });
      finishAction({ action: "publish_broadcast", target: contentId });

      return broadcast;
    },
    [client, db],
  );

  const sendFeedback = React.useCallback(
    async (message: string) => {
      const feedback = await client.uploadLogs({
        deviceContext,
        appContext,
        logs: "",
        userMessage: message,
      });

      return feedback;
    },
    [client, appContext, deviceContext],
  );

  const actionState: ActionState = {
    accountEvent,
    createScheduledWorkflowItem,
    createWorkflowItem,
    deleteItem,
    deleteWorkflowItem,
    publishToWorkspaceFeed,
    publishBroadcast,
    removeMember,
    sendWorkspaceInvites,
    sendFeedback,
    updateMemberRole,
    updateScheduledWorkflowItem,
    subscribeToFeed,
    unsubscribeFromFeed,
    subscribeToFeedGroup,
    unsubscribeFromFeedGroup,
  };

  return (
    <ActionContext.Provider value={actionState}>
      {children}
    </ActionContext.Provider>
  );
};
export default ActionsProvider;
